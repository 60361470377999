import styles from './Shifts.module.css';

import { getShifts } from "../../api.js";
import { DateTime } from 'luxon';
import { Shift } from '../Shift/Shift.js';
import { useState, useEffect, useMemo } from 'react';

const ShiftHeader = ({ shiftCount, page, showPrev, showNext, onPageClick }) => {
    const prevPageClick = () => onPageClick(-1);
    const nextPageClick = () => onPageClick(1);

    return (
        <div className={ styles.header }>
            <span>Event Shifts: { shiftCount }</span>

            <div className={ styles.page }>
                { showPrev ? (<span><i onClick={ prevPageClick } className='fa-solid fa-angles-left fa-sm'></i></span>) : (<span>&nbsp;</span>) }
                <span>&nbsp;{ page }&nbsp;</span>
                { showNext ? (<span><i onClick={ nextPageClick } className='fa-solid fa-angles-right fa-sm'></i></span>) : (<span>&nbsp;</span>) }
            </div>
        </div>
    );
};

export const Shifts = ({ patroller, size = 10, onViewShift, onAssignDuty }) => {
    const [ shifts, setShifts ] = useState([]);
    const [ page, setPage ] = useState(1);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);

    const pageSize = (Math.min(size, 10) - 1);

    const memoizedShifts = useMemo(() => shifts, [shifts]);

    const getShiftsByPage = (p) => {
        const startIdx = (p - 1) * pageSize;
        const endIdx = startIdx + pageSize;
        const pagedDates = memoizedShifts.slice(startIdx, endIdx);
        
        return pagedDates;
    };
    
    // show previous/next page of shifts
    const handlePageClick = (direction) => {
        const newPage = page + direction;
        const newNewPage = newPage ? newPage : newPage + direction;

        if (getShiftsByPage(newNewPage).length === 0) {
            return;
        } else {
            setPage(newNewPage);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const shiftsData = await getShifts();

                if (shiftsData.error) {
                    throw new Error(shiftsData.error);
                }

                const now = DateTime.now().toLocal().toMillis();
                const dateIndex = shiftsData.findIndex(d => DateTime.fromMillis(d.start).toLocal().toMillis() >= now);
                const initialPage = dateIndex > 0 ? Math.floor(dateIndex / pageSize) + 1 : 1;

                setShifts(shiftsData);
                setPage(initialPage);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    
    if (loading) {
        return <p>Loading shifts...</p>;
    }

    if (error) {
        return <p>Error loading shifts: {error}</p>;
    }

    return (
        <>
            <ShiftHeader
                shiftCount={ shifts.length }
                page={ page }
                showPrev={ getShiftsByPage(page - 1).length > 0 }
                showNext={ getShiftsByPage(page + 1).length > 0 }
                onPageClick={ handlePageClick }
            />

            <div className={ styles.shifts }>
                { getShiftsByPage(page).map(f => (
                    <Shift
                        key={ f.id }
                        shift={ f }
                        patroller={ patroller }
                        assigned={ patroller.shifts?.find(s => s.id === f.id) }
                        onViewShift={ onViewShift }
                        onAssignDuty={ onAssignDuty }
                    />
                )) }
            </div>
        </>
    );
};