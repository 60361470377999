import styles from './Dashboard.module.css';

import { auth, signOutUser } from "../../firebase.js";
import { getPatroller, getRadio, setDutyAssignment, clearDutyAssignment, clockInOut } from "../../api.js";
import { useState, useEffect } from "react";
import { ActionBar } from '../ActionBar/ActionBar.js';
import { Patroller } from '../Patroller/Patroller.js';
import { Duties } from '../Duties/Duties.js';
import { Shifts } from '../Shifts/Shifts.js';
import { Radio } from '../Radio/Radio.js';
import { Other } from '../Other/Other.js';

export const Dashboard = () => {
    const [ patroller, setPatroller ] = useState(null);
    const [ radio, setRadio ] = useState({});

    const handleLogOut = async () => {
        signOutUser();
    };

    const handleAssignDuty = async (shift, assigned, role) => {
        const duty = { patroller: { ...patroller, role }, shift, radio };
        const success = assigned ? await clearDutyAssignment(assigned) : await setDutyAssignment(duty);

        if (success) {
            setPatroller(await getPatroller(auth.currentUser.uid));
        }
    };

    const handleOtherShift = async (type) => {
        if (type) {
            // Do something
        } else {
            // Do something else
        }
    };

    const handleOffDuty = async (id) => {
        await clockInOut(auth.currentUser.uid, id);
        
        setPatroller(await getPatroller(auth.currentUser.uid));
    };

    useEffect(() => {
        const fetchData = async () => {
            const radioData = await getRadio(auth.currentUser.uid);

            //setDuties(dutiesData);
            setRadio(radioData);
        };

        fetchData();
    }, []);
    
    return (
        <div className={ styles.dashboard }>
            <ActionBar onLogOut={ handleLogOut } />

            <Patroller id={ auth.currentUser.uid } onSetPatroller={ setPatroller } />
            
            { patroller?.firstName?.length > 0 && (
                <>
                    <Duties duties={ patroller.shifts } onOffDuty={ handleOffDuty } onAssignDuty={ handleAssignDuty } />

                    <Shifts patroller={ patroller } onAssignDuty={ handleAssignDuty } />

                    <Radio radio={ radio } editable={ false } />

                    <Other onOtherDuty={ handleOtherShift } />
                </>
            ) }
        </div>
    );
};