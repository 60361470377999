import styles from './Duties.module.css';

import { useState, useMemo } from "react";
import { DateTime } from 'luxon';
import { LocalDate, LocalTime, LocalRelativeTime, LocalDateTime } from '../LocalDateTime/LocalDateTime.js';
import { Shift } from '../Shift/Shift.js';
import { useInterval } from '../../hooks/useInterval.js';

const DutyHeader = ({ assigned }) => {
    const [ time, setTime ] = useState(new Date().getTime());

    useInterval(() => {
        setTime(new Date().getTime());
    }, 2000);

    return (
        <div className={ styles.header }>
            <span>Assigned Shifts:<span>{ assigned }</span></span>
            <span><LocalDateTime date={ time } /></span>
        </div>
    );
};

const Status = ({ nextDuty, onOffDuty, now }) => {
    const today = DateTime.local().toISODate();
    const dutyStartLocal = DateTime.fromMillis(nextDuty?.start, { zone: 'local' });
    const dutyEndLocal = DateTime.fromMillis(nextDuty?.end, { zone: 'local' });
    const timeToNext = dutyStartLocal.diffNow().toObject().milliseconds;
    const showRelativeTime = (now < dutyStartLocal);
    const showActionButton = (now < dutyEndLocal && now >= dutyStartLocal.toMillis() - (15 * 60 * 1000) && !nextDuty?.stopped);
    const showNextShift = (nextDuty?.date > today);

    const handleOffDuty = () => {
        onOffDuty(nextDuty.id);
    };
    
    return (                   
        showActionButton ? (
            <button onClick={ handleOffDuty }>{ nextDuty && !nextDuty.started ? 'start shift' : 'end shift' }</button>
        ) : showRelativeTime ? (
            <p>Next shift: <LocalRelativeTime time={ timeToNext } /></p>
        ) : showNextShift ? (
            <p>Next shift: <LocalDate date={ nextDuty.start } /> at <LocalTime time={ nextDuty.start } /></p>
        ) : (
            <p>No assigned shifts</p>
        )
    );
};

export const Duties = ({ duties, onOffDuty, onViewShift, onAssignDuty }) => {
    const offset = new Date().getTimezoneOffset() * 60000;

    const [ now, setNow ] = useState(new Date().getTime());

    const filtered = useMemo(() => {
        return duties?.filter(d => d?.end - offset >= now);
    }, [duties, now, offset]);

    const nextDuty = useMemo(() => {
        return filtered?.find(f => now < f.end && ((!f.started && !f.stopped) || (f.started && !f.stopped)));
    }, [filtered, now]);

    useInterval(() => {
        setNow(DateTime.now().toLocal().toMillis());
    }, 500);

    return (
        <>
            <DutyHeader assigned={ duties?.length }  />

            <div className={ styles.duties }>
                {
                    duties?.map(d =>
                        <Shift
                            key={ d.id }
                            shift={ d }
                            patroller={ d }
                            assigned={ d }
                            nextDuty={ nextDuty }
                            onViewShift={ onViewShift }
                            onAssignDuty={ onAssignDuty }
                        />
                    )
                }

                <Status
                    onOffDuty={ onOffDuty }
                    nextDuty={ nextDuty }
                    now={ DateTime.now() }
                />
            </div>
        </>
    );
};