import { auth, getConfigs } from "./firebase.js";

export const getDuties = async (id) => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`${ getConfigs().api_endpoint }/api/v1/manage/duties/patroller/${ id }`, payload);
        const json = response.ok && await response.json();

        if (Array.isArray(json)) {
            return json.sort((a, b) => a.shift.start - b.shift.start);
        } else {
            return;
        };
    } catch (error) {
        console.error('getDuties:', error);

        return error;
    }
};

export const clockInOut = async (patroller, shift) => {
    try {
        const payload = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ patroller: patroller, shift: shift }) 
        };

        const response = await fetch(`${ getConfigs().api_endpoint }/api/v1/manage/timelogs`, payload);

        return response.ok;
    } catch (error) {
        console.error('clockInOut:', error);

        return error;
    }
};

export const getPatroller = async (id) => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`${ getConfigs().api_endpoint }/api/v1/manage/patrollers/${ id }`, payload);
        
        if (response.ok) {
            return await response.json();
        } else {
            return;
        }
    } catch (error) {
        console.error('getPatroller:', error);

        return error;
    }
};

export const savePatroller = async (patroller) => {
    try {
        const payload = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(patroller)
        };
        console.log('here');
        const response = await fetch(`${ getConfigs().api_endpoint }/api/v1/manage/patrollers`, payload);
        
        if (response.ok) {
            return true;
        } else {
            const json = await response.json();

            return json.error;
        }
    } catch (error) {
        console.error('savePatroller:', error);

        return error;
    }
};

export const getShift = async (id) => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`${ getConfigs().api_endpoint }/api/v1/schedule/shifts/${ id }`, payload);
        
        if (response.ok) {
            return await response.json();
        } else {
            return;
        }
    } catch (error) {
        console.error('getShift:', error);

        return error;
    }
};

export const getShifts = async () => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`${ getConfigs().api_endpoint }/api/v1/manage/shifts`, payload);
        const json = await response.json();

        if (response.ok) {
            return Array.isArray(json) && json.sort((a, b) => a.start - b.start);
        } else {
            return json;
        }
    } catch (error) {
        console.error('getShifts:', error);

        return error;
    }
};

export const clearDutyAssignment = async (id) => {
    try {
        const payload = {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`${ getConfigs().api_endpoint }/api/v1/manage/duties/${ id }`, payload);

        if (response.ok) {
            return true;
        } else {
            return;
        }
    } catch (error) {
        console.error('clearDutyAssignment:', error);

        return error;
    }
};

export const setDutyAssignment = async (duty) => {
    try {
        const payload = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            }
        };

        const shiftPayload = () => {
            const role = duty.patroller.role.name;
            const body = {
                id: duty.shift.id,
                certifications: duty.shift.certifications.map(cert => cert.id)
            };

            body.hillCaptain = role === 'Hill Captain' ? duty.patroller.id : duty.shift.hillCaptain;
            body.patrollers = role === 'Patroller' ? { ...duty.shift.patrollers, [ duty.patroller.id ]: true } : duty.shift.patrollers;

            return {
                ...payload,
                body: JSON.stringify(body)
            };
        };

        const patrollerPayload = () => {
            return {
                ...payload,
                body: JSON.stringify({
                    patroller: duty.patroller.id,
                    shift: duty.shift.id
                })
            };
        };
        
        const [ addShiftResponse, addPatrollerResponse ] = await Promise.all([
            fetch(`${getConfigs().api_endpoint}/api/v1/manage/shifts`, shiftPayload()),
            fetch(`${ getConfigs().api_endpoint }/api/v1/manage/patrollers`, patrollerPayload())
        ]);

        if (addShiftResponse.ok && addPatrollerResponse.ok) {
            return true;
        } else {
            if (!addShiftResponse.ok) {
                console.error('Shift API call failed', await addShiftResponse.text());
            }

            if (!addPatrollerResponse.ok) {
                console.error('Patroller API call failed', await addPatrollerResponse.text());
            }

            return false;
        }
    } catch (error) {
        console.error('setDutyAssignment:', error);

        return error;
    }
};

export const getRadio = async (id) => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`${ getConfigs().api_endpoint }/api/v1/manage/radios/${ id }`, payload);
        
        if (response.ok) {
            return await response.json();
        } else {
            return {};
        }
    } catch (error) {
        console.error('getRadio:', error);

        return error;
    }
};

export const uploadPhoto = async (photo) => {
    try {
        const formData = new FormData();

        formData.append('id', auth.currentUser.uid);
        formData.append('photo', photo);

        const payload = {
            method: 'POST',
            body: formData
        };

        const response = await fetch(`${ getConfigs().api_endpoint }/api/v1/manage/patrollers/photos`, payload);

        if (response.ok) {
            return true;
        } else {
            return;
        }
    } catch (error) {
        console.error('uploadPhoto:', error);

        return error;
    }
};

export const getRoster = async (id) => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(false) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = id && await fetch(`${ getConfigs().api_endpoint }/api/v1/schedule/rosters/${ id }`, payload);
        
        if (response.ok) {
            return await response.json();
        } else {
            return;
        }
    } catch (error) {
        console.error('getRoster:', error);

        return error;
    }
};