import styles from './Shift.module.css';

import { DateTime } from 'luxon';
import { LocalDate, LocalTime } from '../LocalDateTime/LocalDateTime.js';
import { useState } from 'react';
import { useInterval } from '../../hooks/useInterval.js';
import { Roster } from '../Roster/Roster.js';
import { Roles } from '../Roles/Roles.js';

const Alert = ({ shift, next, assigned, available, late, started, stopped, onAssignDuty }) => {
    const [ shiftToday, setShiftToday ] = useState(null);
    const [ shiftUpcoming, setShiftUpcoming ] = useState(null);
    const [ oldShift, setOldShift ] = useState(null);
    const [ warning, setWarning ] = useState(null);

    const addShift = !assigned && available;
    const shiftActive = started && !stopped;
    const shiftCompleted = started && stopped;

    useInterval(() => {
        const now = DateTime.local();
        const today = now.toISODate();
        const shiftStart = shift?.start && DateTime.fromMillis(shift.start).toISODate();
        const shiftEnd = shift?.end && DateTime.fromMillis(shift.end).toISODate();
    
        setShiftToday((next || assigned) && shiftStart === today && now < DateTime.fromMillis(shift.start));
        setWarning(shiftStart <= today && now >= DateTime.fromMillis(shift.end) && (!stopped && !started));
        setShiftUpcoming((next || assigned) && shiftStart > today);
        setOldShift(shiftStart < today);
    }, 500);

    return (
        addShift ? (
            <span><i onClick={ onAssignDuty } className='fa-solid fa-plus fa-sm'></i></span>
        ) : shiftUpcoming ? (
            <span><i onClick={ onAssignDuty } className='fa-solid fa-hourglass fa-sm'></i></span>
        ) : shiftActive ? (
            <span><i className='fa-solid fa-clock fa-sm'></i></span>
        ) : shiftCompleted ? (
            <span><i className='fa-solid fa-check fa-sm'></i></span>
        ) : late ? (
            <span><i onClick={ onAssignDuty } className='fa-solid fa-bell fa-flip fa-sm'></i></span>
        ) : shiftToday ? (
            <span><i onClick={ onAssignDuty } className='fa-solid fa-person-skiing fa-sm'></i></span>
        ) : warning ? (
            <span><i className='fa-solid fa-triangle-exclamation fa-fade fa-sm'></i></span>
        ) : oldShift ? (
            <span>&nbsp;</span>
        ) : (
            <span>&nbsp;</span>
        )
    );
};

export const Shift = ({ shift, patroller, assigned, nextDuty, onAssignDuty }) => {
    const [ showRoster, setShowRoster ] = useState(null);
    const [ showRole, setShowRole ] = useState(null);
    const [ shiftAvailable, setShiftAvailable ] = useState(null);
    const [ shiftDisabled, setShiftDisabled ] = useState(null);
    const [ shiftNext, setShiftNext ] = useState(null);
    const [ shiftLate, setShiftLate ] = useState(null);
    
    const shiftName = (!nextDuty ? styles[ shift.name ] : null);
    const shiftAssigned = (assigned ? styles.assigned : null);
    const shiftStarted = (assigned?.started ? styles.started : null);
    const shiftStopped = (assigned?.stopped ? styles.stopped : null);

    const handleAssignShift = (role) => {
        setShowRole(false);
        
        // TODO: possibly change 'assigned && assigned.id' to just 'assigned'; it might simplify/standardize clearDutyAssignment/setDutyAssignment
        onAssignDuty(shift, assigned && assigned.id, role);
    };

    const handleShowRoster = () => {
        setShowRole(false);
        setShowRoster(!showRoster);
    };

    const handleShowRole = () => {
        setShiftAvailable(null);
        setShowRole(!showRole);
    };

    useInterval(() => {
        const now = DateTime.local().toMillis();
        const today = DateTime.local();
        const shiftStartTime = DateTime.fromMillis(shift.start).toLocal().toMillis();
        const shiftEndTime = DateTime.fromMillis(shift.end).toLocal().toMillis();
        const shiftDate = shiftStartTime && DateTime.fromMillis(shift.start).toLocal();
        const shiftEnd = now > shiftEndTime;
        const shiftStart = now >= shiftStartTime;
        const shiftActive = shiftStart && !shiftEnd;
        
        setShiftAvailable(shift.available > 0 && shiftDate >= today);
        setShiftNext(assigned && now < shiftEndTime ? styles.next : null);
        setShiftLate(assigned && shiftActive && !assigned.started ? styles.late : null);
        setShiftDisabled((assigned && shiftEnd) || (!assigned && (!shift.available || shiftEnd)) ? styles.disabled : null);
    }, 500);

    return (
        <div className={ `${ styles.shift }` }>
            <div className={ `${ styles.item } ${ shiftName } ${ shiftDisabled } ${ shiftAssigned } ${ shiftNext } ${ shiftLate } ${ shiftStarted } ${ shiftStopped }` }>
                <span onClick={ handleShowRoster }>{ shift.name }<sup>{ shift.available }</sup></span>
                <LocalDate date={ shift.start } onClick={ handleShowRoster } />
                <LocalTime time={ shift.start } onClick={ handleShowRoster } />
                <LocalTime time={ shift.end } onClick={ handleShowRoster } />
                <Alert
                    shift={ shift }
                    next={ nextDuty }
                    assigned={ assigned }
                    available={ shiftAvailable }
                    late={ shiftLate }
                    started={ shiftStarted }
                    stopped={ shiftStopped }
                    onAssignDuty={ handleShowRole }
                />
            </div>

            { showRoster ? (
                <Roster patrollers={ shift.patrollers.sort((a, b) => a?.lastName.localeCompare(b?.lastName)) } hillCaptain={ shift.hillCaptain } />
            ) : showRole ? (
                <Roles shift={ assigned ? assigned : shift } patroller={ patroller } onRoleSelect={ handleAssignShift } />
            ) : null }
        </div>
    );
};