import styles from './Roles.module.css';

const Role = ({ role, onRoleSelect }) => {
    const handleRoleSelect = () => {
        onRoleSelect(role);
    };

    return (
        <button onClick={ handleRoleSelect }>{ role.name }</button>
    );
};

export const Roles = ({ shift, patroller, onRoleSelect }) => {
    const isAssigned = shift.hillCaptain?.id === patroller.id || shift.patrollers?.find(p => p.id === patroller.id);
    const allowedCertifications = shift?.certifications?.filter(cert => patroller.certifications.some(c => c.id === cert.id));

    // Determine if the Hill Captain role is already assigned
    const hillCaptainAssigned = !!shift.hillCaptain;

    // Determine the number of patrollers already assigned
    const patrollersAssigned = shift.patrollers?.length || 0;
    const patrollerSlots = shift.slots.find(s => s.role === 'Patroller').required;

    // Filter available roles
    const availableRoles = allowedCertifications?.filter(cert => {
        if (cert.name === 'Hill Captain') {
            return !hillCaptainAssigned;
        }

        return patrollersAssigned <= patrollerSlots && shift.available > 0;
    });

    return (
        <div className={ styles.roles }>
            { availableRoles?.length > 0 ? (
                availableRoles.map(role => (
                    <Role key={ role.id } role={ role } onRoleSelect={ onRoleSelect } />
                ))
            ) : isAssigned ? (
                <div>{ patroller.id }</div>
            ) : (
                <div>No roles available</div>
            ) }
        </div>
    );
};